<template>
  <div class="flex flex-col box items-center p-4" style="height: 70%;">
    <section v-if="vista === 0" class="flex flex-col gap-4 w-1/2 p-4 justify-center">
      <div class="flex flex-col gap-2">
        <label>Tipo de Documento</label>
        <Dropdown
          v-model="values.TipoFormato"
          :options="TiposFormatos"
          optionLabel="nombre"
          optionValue="id"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
        />
        <span class="text-red-500 text-xs">{{ errors.TipoFormato }}</span>
      </div>

      <div class="flex flex-col gap-2">
        <label>Tipo de solicitud</label>
        <Dropdown
          v-model="values.tipoId"
          :options="tiposSolicitud"
          optionLabel="nombre"
          optionValue="id"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
          @change="onChangeTipoSolicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.tipoId }}</span>
      </div>

      <div class="flex flex-col gap-2">
        <label>Ubicación del Documento o Carpeta</label>
        <TreeSelect
          v-model="values.rutaSelected"
          :options="mapaProcesos"
          placeholder="Seleccione el documento o carpeta"
          class="md:w-20rem w-full p-inputtext-xs" />
        <span class="text-xs text-red-500" v-if="errors.rutaSelected">{{ errors.rutaSelected }}</span>
      </div>

      <!-- <div v-if="values.tipoId !== 1" class="flex flex-col gap-2">
        <label>Categoria</label>
        <Dropdown
          v-model="values.opcionProceso"
          :options="categorias"
          optionLabel="label"
          optionValue="value"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
        />
        <span class="text-red-500 text-xs">{{ errors.opcionProceso }}</span>
      </div> -->
      <div class="flex flex-col gap-2 w-full">
        <label>Asunto Solicitud</label>
        <InputText
          v-model="values.asunto"
          class="p-inputtext-xs"
          placeholder="Escriba el titulo de la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.asunto }}</span>
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label>Descripción</label>
        <Textarea
          v-model="values.solicitud"
          rows="3"
          cols="30"
          class="p-inputtext-xs"
          placeholder="Escriba la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.solicitud }}</span>
      </div>
    </section>
    <section v-if="vista === 1" class="w-full p-4">
      <div class="flex justify-end">
        <Button
          label="Previsualizar"
          icon="pi pi-check"
          @click="previsualizar"
        />
      </div>
      <div class="flex justify-around">
        <Editor
        ref="editor"
        api-key="ija5lmgiqtvwta2or2gt1qzgthnp3oqw54atulwcu85vidqe"
        :init="{
                plugins: 'preview importcss searchreplace autolink directionality code visualblocks visualchars template codesample table pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars accordion',
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | pagebreak | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist  | lineheight outdent indent| forecolor backcolor removeformat | pagebreak | ltr rtl',
                toolbar_mode: 'sliding',
                language: 'es',
                height: 800,
                width: 830,
                pagebreak_separator: '<!-- my page break -->',
                content_style: 'body  { margin-left: 4rem!important; margin-right: 4rem!important; } '
              }"
          v-model="dataEditor"
        />
      </div>
    </section>
    <section v-if="vista === 2" class="flex flex-col w-full p-4">
      <!-- <FormatoExcel v-if="values.TipoFormato == 2" ref="componentExcel"></FormatoExcel> -->
      <div v-if="values.TipoFormato == 2" class="flex flex-col gap-2 w-full">
        <div class="">
          <label>Cargar Formato</label>
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </section>
    <section class="flex justify-center gap-12">
      <template v-if="vista === 0">
        <Button label="Cancelar" icon="pi pi-times" @click="closeModal(1)" class="p-button-text"/>
      </template>
      <template v-if="vista === 1 || vista === 2">
        <Button label="Atras" icon="pi pi-arrow-left" @click="closeModal(0)" class="p-button-text"/>
      </template>
      <template v-if="btnSiguiente">
        <Button label="Siguiente" icon="pi pi-arrow-right" @click="siguiente()"/>
      </template>
      <!-- <template v-if="!btnSiguiente && values.TipoFormato == 1">
        <Button label="Previsualizar" icon="pi pi-check" @click="previsualizar()"/>
      </template> -->
      <template v-if="!btnSiguiente">
        <Button label="Crear" icon="pi pi-check" @click="onSubmit(2)"/>
      </template>
    </section>
    <!-- inicio modal previsualizar -->
    <Dialog v-model:visible="displayVerPdf" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '80vw' }">
      <div class="mb-5" style="height:80vh">
        <embed v-if="filePath && 'base' in filePath.data" alt="Nombre del archivo PDF" type="application/pdf" :src="`data:application/pdf;base64,${filePath.data.base}#zoom=80&bookmarks=1`" style="width:100%;height: 100%;">
        <embed v-else alt="Nombre del archivo PDF" type="application/pdf" :src="filePdf" style="width:100%;height: 100%;">
      </div>
      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined"
                @click="displayVerPdf = false"/>
      </template>
    </Dialog>
    <!-- fin modal previsualizar -->
  </div>
</template>

<script setup>
import Editor from '@tinymce/tinymce-vue'
import { computed, onMounted, ref, watch } from 'vue'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import FilePondStore from '../filepond.store'
import FilePond from './filePound.vue'
import TreeSelect from 'primevue/treeselect'

  // components: { Editor, FilePond },
    // Computed
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )

    // Referencias
    const router = useRouter()

    // Services
    const _modalSolicitudesService = new ModalSolicitudesService()

    // Data
    const vista = ref(0)
    const btnSiguiente = ref(true)
    const dataEditor = ref('')
    const dataDoc = ref('')
    const areas = ref([])
    const carpetas = ref([])
    const subcarpeta = ref(false)
    const pond = ref()
    const displayVerPdf = ref()
    const filePath = ref('')
    const filePdf = ref('')
    const TiposFormatos = ref(
      [
        { id: 1, nombre: 'Procedimientos, Protocolos, Manuales' },
        { id: 2, nombre: 'Listas de chequeo, Formatos' }
      ]
    )
    const mapaProcesos = ref([])
    // const nodes = ref(
    //   [
    //     {
    //         key: '0',
    //         label: 'Documents',
    //         data: 'Documents Folder',
    //         icon: 'pi pi-fw pi-inbox',
    //         children: [
    //             {
    //                 key: '0-0',
    //                 label: 'Work',
    //                 data: 'Work Folder',
    //                 icon: 'pi pi-fw pi-cog',
    //                 children: [
    //                     { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
    //                     { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
    //                 ]
    //             },
    //             {
    //                 key: '0-1',
    //                 label: 'Home',
    //                 data: 'Home Folder',
    //                 icon: 'pi pi-fw pi-home',
    //                 children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
    //             }
    //         ]
    //     }
    //   ])
    const obj = ref({
      tipoProceso: {
        id: null,
        nombre: ''
      },
      proceso: {
        id: null,
        nombre: ''
      },
      carpeta: {
        id: null,
        nombre: ''
      },
      subcarpeta: {
        id: null,
        nombre: ''
      },
      documento: {
        id: null,
        nombre: ''
      }
    })
    const modalSolicitudes = ref(false)
    const tiposSolicitud = ref()
    // const categorias = [
    //   { label: 'Tipo Proceso', value: 1 },
    //   { label: 'Proceso', value: 2 },
    //   { label: 'Carpeta', value: 3 },
    //   { label: 'Documento', value: 4 }
    // ]
    const schema = yup.object({
      tipoId: yup.number().nullable().required('El tipo de solicitud es requerido'),
      TipoFormato: yup.number().nullable().required('El tipo de formato es requerido'),
      asunto: yup.string().nullable().required('El titulo es requerido'),
      solicitud: yup.string().nullable().required('La solicitud es requerida'),
      mapa: yup.number().nullable().required('La ruta del archivo o carpeta es requerida')
      // areas: yup.array().nullable().required('El area es requerida'),
    })
    const { errors, values, handleSubmit, handleReset, validate } = useForm({
      validationSchema: schema
    })
    useField('tipoId', null, { initialValue: null })
    useField('TipoFormato', null, { initialValue: null })
    useField('asunto', null, { initialValue: null })
    useField('solicitud', null, { initialValue: null })
    useField('mapa', null, { initialValue: null })
    // useField('opcionProceso', null, { initialValue: null })
    // useField('tipoProceso', null, { initialValue: null })
    // useField('proceso', null, { initialValue: null })
    // useField('carpeta', null, { initialValue: null })
    // useField('documento', null, { initialValue: null })
    // useField('areas', null, { initialValue: null })

    // Computed

    // Methods
    const cambiarVista = (tipo) => {
      vista.value = tipo
      btnSiguiente.value = !btnSiguiente.value
    }
    const closeModal = (tipo = 0) => {
      if (vista.value === 1 || vista.value === 2) {
        cambiarVista(tipo)
      } else {
        router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
      }
      modalSolicitudes.value = false
      // emit('llamarFuncion')
      this.$emit('llamarFuncion')
    }
    // const alertar = (mensaje) => {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: ` <span style="line-height: normal;">${mensaje}</span>`
    //   })
    // }
    // const openModalValidacion = () => {
    //   pond.value.onSubmitSupport().then(() => {
    //     console.log('procesando archivo...')
    //     if (Object.values(values).some(a => !a)) {
    //       alertar('Faltan campos por llenar')
    //     } else {
    //       if (adjunto.value.urlPath) {
    //         onSubmit(2)
    //       } else {
    //         alertar('Debe seleccionar un archivo')
    //       }
    //     }
    //   }).catch(err => {
    //     console.log(err.message)
    //   })
    // }
    const siguiente = async () => {
      if (values.rutaSelected) {
        const claveNumerica = Object.keys(values.rutaSelected).find(key => typeof parseInt(key) === 'number')
        if (claveNumerica !== undefined) {
          values.mapa = parseInt(claveNumerica)
          // delete values.rutaSelected
        }
      }
      const { valid } = await validate()
      if (valid) cambiarVista(values.TipoFormato)
      if (values.tipoId === 2) {
        const { data } = await _modalSolicitudesService.getTxt({ mapa: values.mapa })
        dataDoc.value = data.documento[0]
        dataEditor.value = data.txt
      } else {
        dataEditor.value = ''
      }
    }
    const onSubmit = async (tipo) => {
        const submit = handleSubmit(async (values) => {
          if (!values) throw new Error('Error de validación')
          // let { areas } = values
          // delete values.areas
          // areas = areas.map((item) => {
          //   return item.id
          // })
          if (values.TipoFormato === 1) {
            if (dataEditor.value === '') {
              Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe ingresar el contenido del documento'
              })
              return
            }
          }
          var dataSolicitud = {
            TipoFormato: values.TipoFormato,
            tipoId: values.tipoId,
            solicitud: values.solicitud,
            asunto: values.asunto,
            // ruta: values.ruta,
            mapa: values.mapa,
            path: dataEditor.value
            // areas: JSON.stringify(areas)
          }
          if (values.TipoFormato === 2) {
            await pond.value.onSubmitSupport().then(() => {
            console.log('procesando archivo...')
            }).catch(err => {
              console.log(err.message)
            })
            dataSolicitud.path = adjunto.value.urlPath
            dataSolicitud.tipo = adjunto.value.ext
          }
          _modalSolicitudesService.crearSolicitud(dataSolicitud).then((res) => {
            handleReset()
            Swal.fire({
              icon: 'success',
              title: 'Solicitud creada',
              text: 'La solicitud se ha creado correctamente'
            }).then(() => {
              router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
            })
          })
        })
        await submit()
    }
    const onChangeTipoSolicitud = () => {
      const temp = values.tipoId
      // clear()
      values.tipoId = temp
      if (values.tipoId === 1 || values.tipoId === 2) {
        values.opcionProceso = 4
      }
    }
    const previsualizar = async () => {
      filePath.value = await _modalSolicitudesService.getTxtPdf({ ...dataDoc.value, txt: dataEditor.value.toString() })
      displayVerPdf.value = true
      const url = await _modalSolicitudesService.getUri({ path: filePath.value.data.pdf, tipo: 'pdf', nombre: 'nombre temporal' })
      filePdf.value = url.base ? url.base : url
    }

    // Watchers
    watch(() => obj.value.carpeta, (val) => {
      console.log('el hijo', carpetas.value.find(f => f.id === val.id))
      const carp = carpetas.value.find(f => f.id === val.id)
      if (carp) {
        if (carp.hijo.length) {
          subcarpeta.value = true
        }
      }
    })
    watch(() => values.tipoId, (val) => {
      btnSiguiente.value = val === 1 || val === 2
    })
    watch(() => values.opcionProceso, (val) => {
      btnSiguiente.value = val === 4
      // if (val === 4 ) {
      //
      // }
      if (val === 1) {
        obj.value = {
          tipoProceso: { ...obj.value.tipoProceso },
          proceso: { id: null, nombre: '' },
          carpeta: { id: null, nombre: '' },
          documento: { id: null, nombre: '' }
        }
      } else if (val === 2) {
        obj.value.carpeta = { id: null, nombre: '' }
        obj.value.documento = { id: null, nombre: '' }
      } else if (val === 3) {
        obj.value.documento = { id: null, nombre: '' }
      }
    })

    // OnMounted
    onMounted(() => {
      _modalSolicitudesService.getTiposSolicitud().then((res) => {
        tiposSolicitud.value = res.data
      })
      _modalSolicitudesService.getAreasList().then((res) => {
        areas.value = res.data
      })
      _modalSolicitudesService.getMapaProcesos().then((res) => {
        mapaProcesos.value = res.data.map(n1 => {
          return {
            key: n1.id,
            label: n1.nombre,
            data: n1.nombre,
            icon: 'pi pi-fw pi-cog',
            children: n1.hijos.map(n2 => {
              return {
                key: n2.id,
                label: n2.nombre,
                data: n2.nombre,
                icon: 'pi pi-fw pi-cog',
                children: n2.hijos.map(n3 => {
                  return {
                    key: n3.id,
                    label: n3.nombre,
                    data: n3.nombre,
                    children: !n3.hijos ? null : n3.hijos.map(n4 => {
                      return {
                        key: n4.id,
                        label: n4.nombre,
                        data: n4.nombre,
                        children: !n4.hijos ? null : n4.hijos.map(n5 => {
                        return {
                          key: n5.id,
                          label: n5.nombre,
                          data: n5.nombre
                        }
                      })
                      }
                    })
                  }
                })
              }
            })
          }
        })
      })
    })

</script>

<style scoped>

</style>
